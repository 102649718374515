import axios from 'axios';
import {ContactUsFormData} from "@/components/contact-us/ContactUsComponentV2";

export class MailService {

	private readonly BASE_URL;

	constructor(BASE_URL?: string) {
		this.BASE_URL = BASE_URL || process.env.NEXT_PUBLIC_BASE_URL || "http://localhost:3000";
	}
	public async sendMail(mail: EmailConfiguration): Promise<void> {
		return axios.post(`${this.BASE_URL}/api/mail`, mail).then(res => res.data)
	}

	public async sendContactUsMail(formData: ContactUsFormData): Promise<void> {
		return axios.post(`${this.BASE_URL}/api/mail/contact-us`, formData).then(res => res.data)
	}
}
