'use client'
import classNames from "classnames";
import IntrinsicAttributes = React.JSX.IntrinsicAttributes;
import React, {useEffect, useState} from "react";
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

export default function GenericPhoneInput({
	                                          title,
	                                          className,
	                                          iconUrl,
	                                          placeholder,
	                                          id,
	                                          name,
	                                          type,
	                                          required,
	                                          pattern,
	                                          componentSize
                                          }: GenericInputProps) {
	const [phone, setPhone] = useState('');

	const getComponentSize = () => {
		switch (componentSize) {
			case "sm":
				return "h-10 md:h-12 xl:h-12 text-base !pl-2";
			case "lg":
			default:
				return "h-12 xl:h-15 text-sm";
		}
	}

	return (
		<div>
			<PhoneInput
				id={id}
				placeholder={placeholder}
				value={phone}
				onChange={setPhone}
				defaultCountry="US"
				numberInputProps={{
					className: getComponentSize() + " rounded-r-lg border-2 border-[#e8e8e8] -mr-0.5 px-4 ml-2"

				}}
				className={classNames([getComponentSize(), `pl-5 rounded-lg border-2 border-[#e8e8e8] text-text_dark bg-white px-2}  ${className}`])}
			/>
		</div>
	)
}

interface GenericInputProps extends IntrinsicAttributes, React.ClassAttributes<HTMLInputElement>, React.InputHTMLAttributes<HTMLInputElement> {
	title?: string
	className?: string
	iconUrl?: string,
	placeholder?: string,
	id?: string
	name?: string,
	type?: string,
	required?: boolean
	componentSize?: "sm" | "lg"
}
