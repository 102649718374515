import {ReactNode} from "react";

export function GenericContainer({children, containerRef, id, className, ...props}: GenericContainerProps) {
	return (
		<section ref={containerRef} id={id} className={`w-full px-5 py-10 md:py-[60px] md:px-10 xl:px-[120px] xl:py-[80px] h-fit ${className}`} key={id} {...props} >
			{children}
		</section>
	)
}

export interface GenericContainerProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> {
	id?: string,
	className?: string,
	children: ReactNode,
	containerRef?: any
}
