import Image from "next/image";

export default function ContactUsMobileTitleComponent({
	                                                      title,
	                                                      description
                                                      }: ContactUsMobileTitleComponentProps) {
	return (
		<div className={"w-full flex flex-col text-center items-center justify-center gap-2 md:gap-3 xl:hidden"}>
			<Image

				src={"/logo/Logo_black.svg"} alt={"Formica Logo"} width={140} height={34}/>
				<p className={"text-lg md:text-2.5xl font-medium text-text_dark whitespace-pre-wrap"}>{title}</p>
				<p className={"text-xs md:text-base text-text_light font-light whitespace-pre-wrap"}>{description}</p>
		</div>
	)
}


export interface ContactUsMobileTitleComponentProps {
	title?: string,
	description?: string,
}
