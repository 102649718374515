import {GenericContainer} from "@/components/generic/GenericContainer";
import React from "react";
import classNames from "classnames";
import {motion} from "framer-motion";

export default function ContactUsFeatureComponent({
	                                                  topTitle,
	                                                  title,
	                                                  subtitle,
	                                                  description,
	                                                  containerClassName,
	                                                  customChildren
                                                  }: ContactUsFeatureComponentProps) {

	const formattedTitle = title?.replace(/(\d+)/, '<span class="text-formica">$1</span>');

	return (
		<GenericContainer
			className={classNames(
				"px-4 md:px-10 xl:!px-10 py-4 md:py-10 xl:!py-10",
				"bg-contactus-model-mobile-bg md:bg-contactus-model-tablet-bg xl:bg-contactus-model-bg bg-no-repeat bg-100% rounded-xl",
				"relative flex flex-col xl:flex-row",
				"z-10 justify-start items-start",
				"!w-[335px] max-w-[335px] !h-[240px] max-h-[240px]",
				"md:!w-[688px] md:max-w-[688px] md:!h-[486px] md:max-h-[486px]",
				"xl:!w-[510px] xl:max-w-[510px] xl:!h-[564px] xl:max-h-[564px]",
				`min-h-max text-start gap-1 xl:gap-0 gap-x-12 ${containerClassName}`
			)}
		>
			<div className={"xl:mb-5 w-full"}>
				{topTitle && <p className={"text-lg pb-3 font-normal text-formica"}>{topTitle}</p>}
				{title && (
					<p
						className={"text-base md:text-xl xl:text-2xl pb-0 xl:pb-1 font-semibold text-text_dark whitespace-pre-wrap"}
						dangerouslySetInnerHTML={{__html: formattedTitle}}
					/>
				)}
				{description && (
					<p className={classNames("text-text_light font-light text-sm xl:text-base mt-3 xl:mt-5")}>
						{description}
					</p>
				)}
			</div>
			<motion.div
				className={classNames(
					"absolute min-w-[159px] md:min-w-[269px] xl:min-w-[300px] bottom-0 right-0 z-20 flex items-center justify-center bg-white rounded-lg md:rounded-xl px-4 md:px-6 py-4 md:py-[22px] xl:py-[18px] border-2 border-formica",
					"shadow-quick-response"
				)}>
				<p className={"text-base md:text-2.5xl xl:text-3.5xl text-text_dark font-semibold"}>{subtitle}</p>
			</motion.div>
			{customChildren && customChildren}
		</GenericContainer>
	);
}

export interface ContactUsFeatureComponentProps {
	topTitle?: string;
	title?: string;
	icon?: string;
	subtitle?: string;
	description?: string;
	containerClassName?: string;
	key?: string | number;
	customChildren?: React.ReactNode;
}
