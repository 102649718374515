'use client'
import {GenericContainer} from "@/components/generic/GenericContainer";
import Image from "next/image";
import {motion, useInView} from "framer-motion";
import {useRef} from "react";
import LetterCardComponent from "@/components/try-now/LetterCardComponent";

export const TryNowComponent = ({title, subtitle, buttonTitle, buttonHref, description}: TryNowComponentProps) => {
	const containerRef = useRef(null);
	const isInView = useInView(containerRef, {
		once: true,
		margin: "0px 0px -400px 0px"
	});

	const variants = {
		initial: {
			y: 0,
			clipPath: "polygon(0 0, 100% 0%, 100% 70%, 0 70%)"
		},
		animate: {
			y: -100,
			clipPath: "polygon(0 0, 100% 0%, 100% 100%, 0 100%)",
			transition: {
				duration: 0.5
			}
		}
	}

	return (
		<GenericContainer containerRef={containerRef}
		                  className={"justify-center justify-items-center text-center flex gap-5 xl:gap-8 min-h-[280px] md:min-h-[360px] xl:min-h-[460px] !py-[0px] !px-0"}>
			<div className={"bg-try-now-mobile-bg xl:bg-try-now-bg bg-no-repeat bg-100% w-full"}>
				<div className={"w-full h-full relative flex justify-end items-center flex-col"}>
					<Image src={"/try-now/Letter-Back.webp"}

					       width={406} height={0} alt={"Letter Back"} className={"absolute w-[280px] md:w-[321px] xl:w-[406px]"}/>
					<motion.div
						variants={variants}
						initial={"initial"}
						animate={isInView ? "animate" : "initial"}
						className={"w-[279px] md:w-[320px] xl:w-[405px] -bottom-[70px] md:-bottom-16 xl:bottom-[-60px] absolute [clip-path:polygon(0_0,100%_0%,100%_70%,0_70%)]"}
					>
						<LetterCardComponent/>
					</motion.div>
					<Image src={"/try-now/Letter-Front.webp"}

					       width={406} height={0} alt={"Letter Front"} className={"absolute w-[280px] md:w-[321px] xl:w-[406px]"}/>
				</div>
			</div>
		</GenericContainer>
	);
};

interface TryNowComponentProps {
	title?: string,
	subtitle?: string,
	description?: string,
	buttonTitle?: string,
	buttonHref?: string
}
