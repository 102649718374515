'use client';
import Image from "next/image";
import {GenericContainer} from "@/components/generic/GenericContainer";
import GenericInput from "@/components/generic/GenericInput";
import GenericTextArea from "@/components/generic/GenericTextArea";
import {ChangeEvent, FormEvent, useState} from "react";
import {MailService} from "@/service/MailService";
import GenericPhoneInput from "@/components/generic/GenericPhoneInput";
import {useTranslations} from "next-intl";
import GenericButton from "@/components/generic/GenericButton";
import ContactUsFeatureComponent from "@/components/contact-us/ContactUsFeatureComponent";
import ContactUsMobileTitleComponent from "@/components/contact-us/ContactUsMobileTitleComponent";

export default function ContactUsComponent({containerClassName}: ContactUsComponentProps) {
	const [status, setStatus] = useState<Status | undefined>(undefined);
	const [message, setMessage] = useState<string | undefined>(undefined);
	const mailService = new MailService();
	const t = useTranslations('');

	const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const {email, firstname, lastname, jobtitle, newsletter, message, tel, country} = e.currentTarget.elements as any;
		const formData: ContactUsFormData = {
			email: email.value,
			firstname: firstname.value,
			lastname: lastname.value,
			jobtitle: jobtitle.value,
			newsletter: newsletter.checked,
			value: message.value,
			tel: tel.value,
			country: country.value
		}
		mailService.sendContactUsMail(formData).then(r => {
			setMessage(t("contactUs.form.successMessage"));
			setStatus(Status.SUCCESS);
		}).catch(e => {
			setMessage(t("contactUs.form.errorMessage"));
			setStatus(Status.ERROR);
		})
	}

	return (
		<GenericContainer
			id={"contact-us"}
			className={`scroll-mt-[80px] min-h-max h-[600px] flex flex-col xl:flex-row justify-center items-center gap-10 md:gap-[52px] xl:gap-x-12 bg-[#F3F5F7] ${containerClassName}`}>
			{
				status == Status.SUCCESS &&
				<div className={"flex flex-col w-full justify-center items-center text-center xl:text-start"}>
					<div className={"mb-5 flex flex-col items-center"}>
						<Image

							src={"/logo/Logo_black.svg"} alt={"Formica Logo"} width={140} height={34}/>
						<p className={"text-xl font-medium text-text_dark text-center pt-9"}>
							{<div dangerouslySetInnerHTML={{__html: message as TrustedHTML}}/> || t("contactUs.form.successMessage")}
						</p>
					</div>
				</div>
			}
			{(status == undefined || status == Status.ERROR || status == Status.LOADING) &&
				<>
					<ContactUsMobileTitleComponent title={t("contactUs.mobileTitle")} description={t("contactUs.mobileDescription")}/>
					<div
						className={"mb-5 items-center xl:items-start flex flex-col max-w-[510px] h-full justify-center text-center xl:text-start"}>
						<ContactUsFeatureComponent
							title={t("contactUs.feature.title")}
							subtitle={t("contactUs.feature.subtitle")}/>
					</div>
					<div className={"flex flex-col gap-6"}>
						<Image

							src={"/logo/Logo_black.svg"} className={"hidden xl:flex"} alt={"Formica Logo"} width={131} height={32}/>
						<form
							className={"gap-3 xl:gap-3 flex flex-col max-w-[510px] md:max-w-[768px] xl:max-w-[510px] text-sm xl:text-base"}
							onSubmit={handleSubmit}>
							<div className={"grid gap-2 md:grid-cols-2 md:gap-y-3 md:gap-x-3"}>
								<GenericInput type={"text"} id={"firstname"} name={"firstname"} iconUrl={"name-icon"}
								              componentSize={"sm"}
								              placeholder={t("contactUs.form.firstName")} required/>
								<GenericInput type={"text"} id={"lastname"} name={"lastname"} iconUrl={"name-icon"} componentSize={"sm"}
								              placeholder={t("contactUs.form.lastName")} required/>
								<GenericInput type={"email"} id={"email"} name={"email"} iconUrl={"email-icon"} componentSize={"sm"}
								              placeholder={t("contactUs.form.email")} required/>
								<GenericInput type={"text"} id={"jobtitle"} name={"jobtitle"} iconUrl={"job-title-icon"}
								              componentSize={"sm"}
								              placeholder={t("contactUs.form.jobTitle")} required/>
								<GenericPhoneInput type={"tel"} id={"tel"} name={"tel"} iconUrl={"phone-icon"} componentSize={"sm"}
								                   placeholder={t("contactUs.form.phone")}/>
								<GenericInput type={"country"} id={"country"} name={"country"} iconUrl={"country-icon"}
								              componentSize={"sm"}
								              placeholder={t("contactUs.form.country")} required/>
							</div>
							<GenericTextArea id={"message"} name={"message"} iconUrl={"enter-message-icon"} componentSize={"sm"}
							                 placeholder={t("contactUs.form.message")} required/>
							<p className={"text-text_light font-light text-base"}>
								{t.rich("contactUs.form.privacyPolicyMessage", {
									"privacyPolicy": (chunks) => {
										return <a href={"/privacy-policy"} className={"text-formica"}>{t(chunks[0])}</a>;
									}
								})}
							</p>
							<div className={"flex items-center gap-x-2 text-text_dark"}>
								<input type="checkbox" id="newsletter" name="Newsletter" className={"w-4 h-4"} defaultChecked/>
								<label htmlFor="newsletter">{t("contactUs.form.newsletter")}</label>
							</div>
							<GenericButton title={t("contactUs.form.submitButton")} variant={"gradientBlue"}
							               isSubmit={true}
							               disabled={status == Status.LOADING}
							               componentSize={"sm"}
							               divClassName={"w-full hover:cursor-pointer"}
							               aClassName={"w-full"}

							/>
							{status == Status.ERROR &&
								<p className={"text-text_light font-light text-xl py-6"}>
									{message || t("contactUs.form.errorMessage")}
								</p>}
						</form>
					</div>
				</>
			}
		</GenericContainer>
	)
}

export interface ContactUsComponentProps {
	containerClassName?: string
}

export interface ContactUsFormData {
	email: string,
	firstname: string,
	lastname: string,
	jobtitle: string,
	newsletter: boolean,
	value: string,
	tel: string,
	country: string
}

enum Status {
	SUCCESS,
	ERROR,
	LOADING
}
