"use client"
import Image from "next/image";
import GenericButton from "@/components/generic/GenericButton";
import {glory} from "../../../app/styles/fonts";
import {useTranslations} from "next-intl";

export default function LetterCardComponent({}: LetterCardComponentProps) {
	const t = useTranslations('tryNow');

	return (
		<div className={`bg-try-now-letter w-[279px] md:w-[320px] xl:w-[405px] h-[238px] md:h-[308px] xl:h-[378px] bg-100% bg-center pb-4 ${glory.className}`}>
			<div className={"flex flex-col items-center justify-center h-full gap-4"}>
				<div className={"flex flex-row gap-3 pb-2"}>
					<Image

						src={"/logo/Logo_black.svg"} alt={"Formica Logo"} className={"w-[39px] h-[9.6px] md:w-[45px] md:h-[11px] xl:w-[57px] xl:h-[14px]"} width={0} height={14}/>
					<div className={"w-[1px] h-[9px] md:h-[11px] xl:h-[14px] bg-divider-gray"}></div>
					<p className={"text-text_dark font-normal text-3xs md:text-2xs xl:text-xs"}>{t("invitation")}</p>
				</div>
				<div className={"flex flex-col px-4 md:px-0 gap-2 max-w-[258px]"}>
					<p className={"text-text_dark font-medium text-xs md:text-xs xl:text-base"}>{t("title")}</p>
					<p className={"text-text_normal_dark font-normal text-2xs md:text-xs xl:text-sm"}>{t("description")}</p>
				</div>
				<div>
					<GenericButton title={t("buttonTitle")} variant={"gradientBlue"} componentSize={"xs"} href={"#contact-us"}/>
				</div>
			</div>
		</div>
	);

}

export interface LetterCardComponentProps {

}
