import classNames from "classnames";
import IntrinsicAttributes = React.JSX.IntrinsicAttributes;
import React from "react";

export default function GenericInput({
	                                     title,
	                                     className,
	                                     iconUrl,
	                                     placeholder,
	                                     id,
	                                     name,
	                                     type,
	                                     required,
	                                     pattern,
	                                     componentSize
                                     }: GenericInputProps) {
	const getComponentSize = () => {
		switch (componentSize) {
			case "sm":
				return "h-10 md:h-12 xl:h-12 text-base pl-[50px]";
			case "lg":
			default:
				return "h-12 xl:h-15 text-sm pl-14";
		}
	}

	return (
		<input
			placeholder={placeholder}
			id={id}
			name={name}
			type={type}
			required={required || false}
			pattern={pattern}
			className={classNames([`bg-${iconUrl} rounded-lg border-2 border-[#e8e8e8] text-text_dark`, getComponentSize(), `${iconUrl ? `bg-no-repeat bg-base bg-left-5-center pr-2` : "bg-white px-2"}  ${className}`])}>
			{title}
		</input>
	)
}

interface GenericInputProps extends IntrinsicAttributes, React.ClassAttributes<HTMLInputElement>, React.InputHTMLAttributes<HTMLInputElement> {
	title?: string
	className?: string
	iconUrl?: string,
	placeholder?: string,
	id?: string
	name?: string,
	type?: string,
	required?: boolean,
	componentSize?: "sm" | "lg"
}
